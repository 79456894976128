<template>
    <div :class="`popup-layout accessRestricted ${inLesson ? 'popup-layout_lesson' : ''}`">
        <div class="popup popup--3">
            <button v-if="!inLesson" class="popup__close" type="button" @click="onClose"></button>
            <p class="h2 popup__title accessRestricted__title">
                Доступ ограничен
            </p>
            <p class="text colorFiord accessRestricted__subTitle">
                Данный контент доступен только для пользователей с пакетом
                <span class="h4 colorFiord" v-if="!accessRestrictedText">{{
                    accessLevelsString
                }}</span>
                <span class="h4 colorFiord" v-if="accessRestrictedText">
                    {{ accessRestrictedText }}
                </span>
                Смените тип подписки и получите дополнительные преимущества:
            </p>
            <div class="form">
                <div class="accessRestricted__content">
                    <span class="text2 colorGray accessRestricted__content_item">
                        <img
                            alt="item-1"
                            class="accessRestricted__content_item_img"
                            src="@/assets/img/pages/webinars/accessRestricted/item-1.svg"
                        />
                        Обратная связь от трейдеров-экспертов по практическим заданиям и
                        написанию алгоритма.
                    </span>

                    <span class="text2 colorGray accessRestricted__content_item">
                        <img
                            alt="item-2"
                            class="accessRestricted__content_item_img"
                            src="@/assets/img/pages/webinars/accessRestricted/item-2.svg"
                        />
                        Возможность посетить 3 онлайн вебинара кураторов Годовой Программы
                        Наставничества.
                    </span>

                    <span class="text2 colorGray accessRestricted__content_item">
                        <img
                            alt="item-3"
                            class="accessRestricted__content_item_img"
                            src="@/assets/img/pages/webinars/accessRestricted/item-3.svg"
                        />
                        Возможность "заморозить" обучение на 14 дней.
                    </span>
                </div>
                <div class="accessRestricted__button">
                    <Button
                        :onClick="() => redirectToUpsellPage()"
                        class=""
                        extraClass="button"
                        title="Изменить пакет"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Alert from "@/views/partials/Alert/Alert";
import axios from "axios";

export default {
    name: "accessRestricted",

    components: { Button, Alert },

    props: ["onClose", "accessRestrictedText", "inLesson"],

    async beforeMount() {
        await this.updateAccessLevelsData();
    },

    data() {
        return {
            accessLevelsString: "",
        };
    },

    methods: {
        onCloseModal() {
            this.onClose();
        },
        async redirectToUpsellPage() {
            await this.$router.push(`/${this.$route.params.slug}/dashboard/packages`);
        },
        updateAccessLevelsData: async function() {
            const getAccessLevelsDataResult = await axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/access_levels?exclude_member_access_level=true"
            );
            if (
                getAccessLevelsDataResult &&
                getAccessLevelsDataResult.data &&
                getAccessLevelsDataResult.data.result
            ) {
                this.accessLevelsString = "";
                if (getAccessLevelsDataResult.data.accesses.length > 0) {
                    for (const [
                        index,
                        access,
                    ] of getAccessLevelsDataResult.data.accesses.entries()) {
                        if (
                            index ===
                            getAccessLevelsDataResult.data.accesses.length - 1
                        ) {
                            this.accessLevelsString += `${access.name}.`;
                        } else {
                            this.accessLevelsString += `${access.name}, `;
                        }
                    }
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке данных пакетов",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "accessRestricted.scss";
</style>
