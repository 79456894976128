<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="(lesson && lesson.blockName) || ''"
            :link="`/${this.$route.params.slug}/study`"
        />

        <div class="pageSection studyLessonContent">
            <div
                class="popup-layout"
                v-if="
                    isVisibleModalLessonOnboarding ||
                        this.$store.getters.getIsReviewOnboardLesson.isReviewOnboardLesson
                "
            ></div>

            <div
                :class="
                    `studyLessonContent__sideBar ${
                        (isVisibleModalLessonOnboarding && isVisibleLessonOnboarding) ||
                        (this.$store.getters.getIsReviewOnboardLesson
                            .isReviewOnboardLesson &&
                            isVisibleLessonOnboarding)
                            ? 'lessonOnboarding'
                            : ''
                    }`
                "
            >
                <OnboardModal
                    v-if="
                        (isVisibleModalLessonOnboarding && isVisibleLessonOnboarding) ||
                            (this.$store.getters.getIsReviewOnboardLesson
                                .isReviewOnboardLesson &&
                                isVisibleLessonOnboarding)
                    "
                    :isActiveSteps="true"
                    currentStep="1"
                    totalSteps="3"
                    title='Раздел "Уроки"'
                    description="Уроки открываются по очереди, один за одним. Чтобы пройти урок нужно просмотреть видеоурок(и) и выполнить задание (если оно есть)."
                    :isNextButton="true"
                    nextButtonTitle="ДАЛЕЕ"
                    :onClose="() => showedLessonButtonOnboarding()"
                    :onCloseModal="() => showedLessonOnboarding()"
                />

                <OnboardModal
                    style="left: calc(65% + 16px); top: 658px;"
                    v-if="
                        (isVisibleModalLessonOnboarding &&
                            isVisibleLessonButtonOnboarding) ||
                            (this.$store.getters.getIsReviewOnboardLesson
                                .isReviewOnboardLesson &&
                                isVisibleLessonButtonOnboarding)
                    "
                    :isActiveSteps="true"
                    currentStep="2"
                    totalSteps="3"
                    title="Доступ к просмотру видео"
                    description="Чтобы получить доступ к просмотру видео, необходимо установить инфопротектор. Найти его вы сможете по клику на эту кнопку."
                    :isNextButton="true"
                    nextButtonTitle="ДАЛЕЕ"
                    :isBackButton="true"
                    :onBackButton="() => backToLessonOnboarding()"
                    :onClose="() => showedLessonChatOnboarding()"
                    :onCloseModal="() => showedLessonOnboarding()"
                />

                <div class="studyLessonContent__sideBarHeader">
                    <p class="h4 colorGray">Обзор блока</p>

                    <div class="h4 studyLessonContent__sideBarHeader_lessonsProgress">
                        <p>Уроки</p>
                        <p>
                            {{ currentBlock.lessons.filter((i) => i.isPassed).length }}/{{
                                currentBlock.blockCountLessons
                            }}
                        </p>
                    </div>
                </div>
                <div class="studyLessonContent__sideBarContentBox">
                    <div
                        class="studyLessonContent__sideBarContent"
                        v-for="item in currentBlock.lessons"
                        :key="item.itemTitle"
                    >
                        <div
                            @mouseenter="() => mouseInItem(item.lesson.lesson_id)"
                            @mouseleave="() => mouseOutItem()"
                            @click="onOpenSideBarItem(item.lesson.lesson_id)"
                            :class="
                                `studyLessonContent__sideBarContent_cards
                                ${!item.hasAccesToLesson ? 'text colorHeather' : 'text'}`
                            "
                        >
                            <div class="studyLessonContent__sideBarContent_card">
                                <p class="studyLessonContent__sideBarContent_cardText">
                                    <span
                                        v-if="
                                            item.lesson.lesson_number &&
                                                item.lesson.type === 'common'
                                        "
                                        >Урок {{ item.lesson.lesson_number }} –</span
                                    >
                                    {{ item.lesson.title }}
                                </p>
                                <VipUpsellButton
                                    v-if="!item.hasAccesByAccesLevel"
                                    tooltipDirection="right"
                                />
                            </div>

                            <span
                                v-if="
                                    !item.hasAccesToLesson ||
                                        (item.lesson.type === 'additional' &&
                                            item.lessonData &&
                                            (item.lessonData
                                                .additionalLessonStatusByDate ===
                                                'closeEarly' ||
                                                item.lessonData
                                                    .additionalLessonStatusByDate ===
                                                    'unknown'))
                                "
                            >
                                <Tooltip
                                    v-if="item.lesson.type === 'additional'"
                                    tooltipDirection="right"
                                    class="tooltipIcon"
                                    :tooltipIcon="
                                        require('@/assets/img/pages/study/lock.svg')
                                    "
                                    text="Дополнительное задание откроется Вам в среду, ровно в 18:00 по МСК (UTC+3) при условии просмотра всех уроков этого блока и выполнения всех домашних заданий."
                                />
                                <Tooltip
                                    v-else
                                    tooltipDirection="right"
                                    class="tooltipIcon"
                                    :tooltipIcon="
                                        require('@/assets/img/pages/study/lock.svg')
                                    "
                                    text="Этот урок станет для Вас открытым только после просмотра предыдущего и прохождения задания, если оно предусмотрено в предыдущем уроке."
                                />
                            </span>
                            <span v-else>
                                <img
                                    class="studyLessonContent__sideBarContent_img"
                                    v-bind:src="
                                        item.hasAccesToLesson &&
                                        isCardContent &&
                                        selectedSideBarItemId === item.lesson.lesson_id
                                            ? arrowUp
                                            : item.hasAccesToLesson &&
                                              isInsideItem &&
                                              hoveredId === item.lesson.lesson_id
                                            ? arrowDown
                                            : item.hasAccesToLesson &&
                                              ((item.lesson.type !== 'additional' &&
                                                  item.isPassed) ||
                                                  (item.lesson.type === 'additional' &&
                                                      item.lessonData &&
                                                      (item.lessonData
                                                          .additionalLessonStatusByDate ===
                                                          'closeLate' ||
                                                          item.lessonData
                                                              .additionalLessonStatusByDate ===
                                                              'openFinal' ||
                                                          item.lessonData
                                                              .additionalLessonStatusByDate ===
                                                              'close')))
                                            ? doneIcon
                                            : arrowRight
                                    "
                                />
                            </span>
                        </div>

                        <div
                            v-if="
                                isCardContent &&
                                    Number(selectedSideBarItemId) ===
                                        Number(item.lesson.lesson_id) &&
                                    item.hasAccesToLesson
                            "
                            class="studyLessonContent__sideBarContent_inner"
                        >
                            <div
                                v-for="subitem in item.lesson.sideBarItemContent"
                                :key="subitem.id"
                            >
                                <router-link
                                    v-if="
                                        item.hasAccesByAccesLevel &&
                                            !subitem.isLockedItemContent && !subitem.disable
                                    "
                                    class="studyLessonContent__sideBarContent_item"
                                    :to="subitem.link"
                                    @click="
                                        () =>
                                            item.hasAccesByAccesLevel &&
                                            onOpenItem(item.lesson.lesson_id, subitem.id)
                                    "
                                >
                                    <p
                                        class="text2 studyLessonContent__sideBarContent_text"
                                    >
                                        {{ subitem.itemContentTitle }}
                                    </p>
                                    <p
                                        v-if="
                                            !subitem.isWatchItemContent &&
                                                !subitem.isLockedItemContent
                                        "
                                        class="text2 studyLessonContent__sideBarContent_text"
                                    >
                                        {{ subitem.itemContentTime }}
                                    </p>
                                    <img
                                        v-else
                                        class="studyLessonContent__sideBarContent_img"
                                        v-bind:src="
                                            subitem.isLockedItemContent
                                                ? lockIcon
                                                : doneIcon
                                        "
                                    />
                                </router-link>
                                <div
                                    v-else
                                    class="studyLessonContent__sideBarContent_item"
                                >
                                    <p
                                        class="text2 studyLessonContent__sideBarContent_text"
                                    >
                                        {{ subitem.itemContentTitle }}
                                        <VipUpsellButton
                                            v-if="
                                                (subitem.isLockedItemContent &&
                                                    item.lesson.type !== 'additional') || subitem.disable 
                                            "
                                            tooltipType="small"
                                            tooltipDirection="right"
                                        />
                                    </p>
                                    <Tooltip
                                        v-if="subitem.isHomework"
                                        tooltipType="small"
                                        tooltipDirection="right"
                                        class="tooltipIcon"
                                        :tooltipIcon="
                                            require('@/assets/img/pages/study/lock.svg')
                                        "
                                        text="Это задание в открытой форме, которое проверяется экспертами. Данный тип заданий доступен только для пакета VIP."
                                    />
                                    <img
                                        v-else
                                        class="studyLessonContent__sideBarContent_img"
                                        alt="lock"
                                        v-bind:src="lockIcon"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    :class="
                        `studyLessonContent__sideBarFooter ${
                            isVisibleLessonChatOnboarding ||
                            (this.$store.getters.getIsReviewOnboardLesson
                                .isReviewOnboardLesson &&
                                isVisibleLessonChatOnboarding)
                                ? 'lessonChatOnboarding'
                                : ''
                        }`
                    "
                >
                    <OnboardModal
                        style="top: -98px"
                        v-if="
                            (isVisibleModalLessonOnboarding &&
                                isVisibleLessonChatOnboarding) ||
                                (this.$store.getters.getIsReviewOnboardLesson
                                    .isReviewOnboardLesson &&
                                    isVisibleLessonChatOnboarding)
                        "
                        :isActiveSteps="true"
                        currentStep="3"
                        totalSteps="3"
                        title="Задать вопрос эксперту"
                        description="Есть вопросы по материалу? Не поняли лекцию? Напишите свой вопрос и наши эксперты помогут и подскажут Вам."
                        :isNextButton="true"
                        nextButtonTitle="ЗАВЕРШИТЬ"
                        :isBackButton="true"
                        :onBackButton="() => backToButtonOnboarding()"
                        :onClose="() => showedLessonOnboarding()"
                        :onCloseModal="() => showedLessonOnboarding()"
                    />
                    <p class="h3 colorGray studyLessonContent__sideBarFooter_text">
                        Сложности с пониманием материала?&nbsp;
                        <VipUpsellButton v-if="!(enabled && enabled.questions)" />
                    </p>
                    <router-link
                        v-if="enabled && enabled.questions"
                        :to="'/' + $route.params.slug + '/questions'"
                        class="studyLessonContent__sideBarFooter_link"
                    >
                        <span class="btn-text">
                            задать вопрос эксперту
                        </span>
                    </router-link>
                    <p v-else class="studyLessonContent__sideBarFooter_link">
                        <span class="btn-text" @click="() => onOpenModalAccess()">
                            задать вопрос эксперту
                        </span>
                    </p>
                </div>
            </div>
            <div class="studyLessonContent__contentMain">
                <AccessRestricted 
                    v-if="(this.$store.getters.getLesson.lesson && this.$store.getters.getLesson.lesson.courseType === 'dk_3_5' && this.$store.getters.getLesson.lesson.lesson.lesson.type === 'algorithm' && this.$store.getters.getLesson.lesson.memberAccessLevel === 'Базовый' && this.$store.getters.getLesson.lesson.blockName !== 'Блок 9. Написание собственного алгоритма\t')"
                    :onClose="() => onCloseModalAccess()"
                    :inLesson="true"
                />
                <div class="studyLessonContent__contentMain_items" v-else>
                    <router-view></router-view>
                </div>
            </div>
            <AccessRestricted
                v-if="isVisibleModalAccess"
                :onClose="() => onCloseModalAccess()"
            />
            <BlockEvaluationModal
                v-if="
                    isVisibleModalEvaluation &&
                        this.evaluationLessonsState &&
                        !evaluationBlockState
                "
                :onClose="() => onCloseModalEvaluation()"
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Tooltip from "@/views/components/Tooltip/Tooltip";
import Button from "@/views/components/Button/Button";
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";
import AccessRestricted from "@/views/partials/popups/accessRestricted/accessRestricted";

import BlockEvaluationModal from "@/views/pages/study/studyLesson/lessonEvaluation/blockEvaluationModal";

import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

import Lock from "../../../../assets/img/pages/study/lock.svg";
import Done from "../../../../assets/img/pages/study/done.svg";
import ArrowUp from "../../../../assets/img/pages/study/arrowUp.svg";
import ArrowDown from "../../../../assets/img/pages/study/arrowDown.svg";
import ArrowRight from "../../../../assets/img/pages/study/arrowRight.svg";

export default {
    name: "studyLesson",

    components: {
        Layout,
        PageHeader,
        Tooltip,
        Button,
        VipUpsellButton,
        AccessRestricted,
        BlockEvaluationModal,
        OnboardModal,
    },

    async mounted() {
        this.updateLessonStore();
        this.updateLessonEvaluation();

        if (!this.currentBlock.opened) {
            await this.$router.push("/" + this.$route.params.slug + "/study");
        }

        console.log(this.$store.getters.getLesson.lesson)
    },

    async beforeMount() {
        // this.updateMemberStore();
        // this.updateMemberConfirmationsStore();
    },

    watch: {
        "$route.params.lesson_id": function() {
            this.updateLessonStore();
            this.updateLessonEvaluation();
            this.updateMemberStore();
            this.updateMemberConfirmationsStore();
        },
    },

    computed: {
        enabled: function() {
            return (
                (this.$store.getters.getMember &&
                    this.$store.getters.getMember.data &&
                    this.$store.getters.getMember.data.enabled) ||
                {}
            );
        },

        items: function() {
            return [
                {
                    text: "Обучение",
                    to: `/${this.$route.params.slug}/study`,
                },
                {
                    text: (this.lesson && this.lesson.blockName) || "",
                    active: true,
                },
            ];
        },

        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        isVisibleModalLessonOnboarding: function() {
            return (
                // this.isLoadedConfirmations &&
                this.confirmations &&
                this.confirmations.viewedWelcomePopup &&
                !(this.member.agreementData && this.member.agreementData.showRules) &&
                this.confirmations.viewedWelcomeVideo &&
                !this.confirmations.viewedOnBoardingLesson &&
                this.$route.name === "Урок"
            );
        },

        lesson: function() {
            this.selectedSideBarItemId = this.$route.params.lesson_id;
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },

        lessonType: function() {
            return (
                this.$store.getters.getLessonType &&
                this.$store.getters.getLessonType.lessonType
            );
        },

        currentBlock: function() {
            let block =
                this.$store.getters.getUserProgress &&
                this.$store.getters.getUserProgress.progress &&
                this.$store.getters.getUserProgress.progress.data[0] &&
                this.$store.getters.getUserProgress.progress.data[0].blocks[
                    Number(this.$route.params.block_priority) - 1
                ];
            for (let i = 0; i < block.lessons.length; i++) {
                block.lessons[i].lesson.sideBarItemContent = [];
                block.lessons[i].lesson.sideBarItemContent.videos = [];

                if (block.lessons[i].lesson && block.lessons[i].lesson.videos.length) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10,
                        itemContentTitle:
                            block.lessons[i].lesson.type === "additional"
                                ? "Видео-вопрос"
                                : "Видеоурок",
                        itemContentTime:
                            (block.lessons[i].lesson &&
                                block.lessons[i].lesson.videos.length &&
                                block.lessons[i].lesson.videos[0].durationEstimate) ||
                            "",
                        isLockedItemContent: !block.lessons[i].lesson.videos.filter(
                            (v) =>
                                v.accessLevels.indexOf(
                                    this.member.memberAccessLevel__id
                                ) >= 0
                        ).length,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}`,
                    });
                }

                if (
                    block.lessons[i].lesson &&
                    block.lessons[i].lesson.tasks &&
                    block.lessons[i].lesson.tasks.tasksSet.length &&
                    block.lessons[i].lesson.type !== "additional"
                ) {
                    let lockedHomework = true;
                    for (
                        let j = 0;
                        j < block.lessons[i].lesson.tasks.tasksSet.length;
                        j++
                    ) {
                        if (
                            block.lessons[i].lesson.tasks.tasksSet[j].hasAccesByAccesLevel
                        ) {
                            lockedHomework = false;
                            break;
                        }
                    }

                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 1,
                        itemContentTitle:
                            Number(this.$route.params.block_priority) === 10
                                ? "Сделки"
                                : "Задание",
                        itemContentTime: block.lessons[i].durationEstimate
                            ? block.lessons[i].durationEstimate + " мин"
                            : "",
                        isLockedItemContent: !block.lessons[
                            i
                        ].lesson.tasks.tasksSet.filter((i) => i.hasAccesByAccesLevel)
                            .length,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/homework`,
                        isHomework: true,
                    });
                }

                if (
                    block.lessons[i].lesson &&
                    block.lessons[i].lesson.type === "algorithm"
                ) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 2,
                        itemContentTitle: "Алгоритм",
                        itemContentTime: "",
                        isLockedItemContent: false,
                        isWatchItemContent: false,
                        disable: (this.$store.getters.getLesson.lesson && this.$store.getters.getLesson.lesson.courseType === "dk_3_5" && this.$store.getters.getLesson.lesson.memberAccessLevel === 'Базовый') ? true : false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/algorithm`,
                    });
                }

                if (
                    block.lessons[i].lesson &&
                    block.lessons[i].lesson.type === "additional"
                ) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 3,
                        itemContentTitle: "Правильный ответ",
                        itemContentTime: "",
                        isLockedItemContent:
                            (block.lessons[i].lessonsData &&
                                (block.lessons[i].lessonsData
                                    .additionalLessonStatusByDate === "closeEarly" ||
                                    block.lessons[i].lessonsData
                                        .additionalLessonStatusByDate ===
                                        "openToAnswer") &&
                                !block.lessons[i].lessonsData.estimatedAll) ||
                            false,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/correct-answer`,
                    });
                }

                /*if (
                    block.lessons[i].lesson &&
                    block.lessons[i].lesson.type === "additional"
                ) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 4,
                        itemContentTitle: "Оценка ответов других студентов",
                        itemContentTime: "",
                        isLockedItemContent:
                            (block.lessons[i].lessonsData &&
                                (block.lessons[i].lessonsData
                                    .additionalLessonStatusByDate === "closeEarly" ||
                                    block.lessons[i].lessonsData
                                        .additionalLessonStatusByDate ===
                                        "openToAnswer")) ||
                            false,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/estimate`,
                    });
                }*/

                /*if (
                    block.lessons[i].lesson &&
                    block.lessons[i].lesson.type === "additional"
                ) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 5,
                        itemContentTitle: "Оценка Вашего ответа",
                        itemContentTime: "",
                        isLockedItemContent:
                            (block.lessons[i].lessonsData &&
                                (block.lessons[i].lessonsData
                                    .additionalLessonStatusByDate === "closeEarly" ||
                                    block.lessons[i].lessonsData
                                        .additionalLessonStatusByDate ===
                                        "openToAnswer" ||
                                    block.lessons[i].lessonsData
                                        .additionalLessonStatusByDate ===
                                        "openToEstimate")) ||
                            false,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/your-grade`,
                    });
                }*/

                /*if (block.lessons[i].isPassed) {
                    block.lessons[i].lesson.sideBarItemContent.push({
                        id: i * 10 + 6,
                        itemContentTitle: "Оценка материала",
                        itemContentTime: "",
                        isLockedItemContent: false,
                        isWatchItemContent: false,
                        link: `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${block.lessons[i].lesson.lesson_id}/evaluation`,
                    });
                }*/
            }

            return block;
        },

        evaluationBlockState() {
            if (this.$store.getters.getEvaluation.evaluation.blocksEvaluation) {
                return this.$store.getters.getEvaluation.evaluation.blocksEvaluation.find(
                    (itemId) => itemId.blockId === this.currentBlock.blockId
                );
            }
        },

        evaluationLessonsState() {
            let passedBlock = false;

            if (
                this.currentBlock.lessons.filter(
                    (lesson) => lesson.lesson.type === "algorithm"
                ).length
            ) {
                passedBlock =
                    this.currentBlock.lessons.filter(
                        (lesson) => lesson.lesson.type === "algorithm"
                    ).length ===
                    this.currentBlock.lessons.filter(
                        (lesson) => lesson.lesson.type === "algorithm" && lesson.isPassed
                    ).length;
            } else {
                passedBlock =
                    this.currentBlock.lessons.filter(
                        (lesson) => lesson.lesson.type === "common"
                    ).length ===
                    this.currentBlock.lessons.filter(
                        (lesson) => lesson.lesson.type === "common" && lesson.isPassed
                    ).length;
            }

            return passedBlock;
        },
    },

    data() {
        return {
            isCardContent: true,
            isVisibleModal: false,
            isInsideItem: false,
            hoveredId: null,
            selectedSideBarItemId: Number(this.$route.params.lesson_id) || null,
            selectedSidebarSubitemId: null,
            isVisibleModalEvaluation: true,
            isVisibleModalAccess: false,

            lockIcon: Lock,
            arrowDown: ArrowDown,
            arrowUp: ArrowUp,
            arrowRight: ArrowRight,
            doneIcon: Done,
            isLoadedConfirmations: false,
            isVisibleLessonOnboarding: true,
            isVisibleLessonButtonOnboarding: false,
            isVisibleLessonChatOnboarding: false,

            isReviewOnboardLesson: this.$store.getters.getIsReviewOnboardLesson
                .isReviewOnboardLesson,
        };
    },

    methods: {
        mouseInItem(id) {
            this.hoveredId = id;
            this.isInsideItem = true;
        },
        mouseOutItem() {
            this.hoveredId = null;
            this.isInsideItem = false;
        },
        onOpenSideBarItem(id) {
            if (this.selectedSideBarItemId === id) {
                this.isCardContent = !this.isCardContent;
            } else {
                this.selectedSideBarItemId = id;
                this.isCardContent = true;
            }
        },

        onOpenModalAccess() {
            this.isVisibleModalAccess = true;
        },
        onCloseModalAccess() {
            this.isVisibleModalAccess = false;
        },

        onCloseModalEvaluation() {
            this.isVisibleModalEvaluation = false;
        },

        async onOpenItem(id, subId) {
            if (
                this.selectedSideBarItemId !== id ||
                this.selectedSidebarSubitemId !== subId
            ) {
                this.selectedSideBarItemId = id;
                this.selectedSidebarSubitemId = subId;

                await this.updateLessonStore();
            }
        },

        showedLessonButtonOnboarding() {
            this.isVisibleLessonOnboarding = false;
            this.isVisibleLessonButtonOnboarding = true;
            this.$store.dispatch("setIsOnBoardVideo", true);
            window.scrollTo({
                top: 99999999,
                behavior: "smooth",
            });
        },

        backToLessonOnboarding() {
            this.isVisibleLessonOnboarding = true;
            this.isVisibleLessonButtonOnboarding = false;
            this.$store.dispatch("setIsOnBoardVideo", false);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },

        showedLessonChatOnboarding() {
            this.isVisibleLessonButtonOnboarding = false;
            this.isVisibleLessonChatOnboarding = true;
            this.$store.dispatch("setIsOnBoardVideo", false);
        },

        backToButtonOnboarding() {
            this.isVisibleLessonButtonOnboarding = true;
            this.isVisibleLessonChatOnboarding = false;
            this.$store.dispatch("setIsOnBoardVideo", true);
        },

        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (
                    getMemberData.data.code === "programs" ||
                    getMemberData.data.code === "banned" ||
                    getMemberData.data.code === "blocked"
                ) {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async showedLessonOnboarding() {
            if (
                this.isVisibleModalLessonOnboarding &&
                this.$store.getters.getIsReviewOnboardLesson.isReviewOnboardLesson
            ) {
                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/confirmations?confirmation=viewedOnBoardingLesson"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
                this.isVisibleLessonChatOnboarding = false;
                this.$store.dispatch("setIsReviewOnBoardLesson", false);
                await this.$router.push("/" + this.$route.params.slug + "/webinars");
                this.$store.dispatch("setIsReviewOnBoardWebinars", true);
            } else {
                if (this.isVisibleModalLessonOnboarding) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingLesson"
                    );
                    this.updateMemberStore();
                    this.updateMemberConfirmationsStore();
                    this.isVisibleLessonChatOnboarding = false;
                } else {
                    this.$store.dispatch("setIsReviewOnBoardLesson", false);
                    await this.$router.push("/" + this.$route.params.slug + "/webinars");
                    this.$store.dispatch("setIsReviewOnBoardWebinars", true);
                }
            }

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async updateLessonEvaluation() {
            const getEvaluation = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/evaluation"
            );

            if (getEvaluation && getEvaluation.data && getEvaluation.data.result) {
                await this.$store.dispatch("setEvaluation", getEvaluation.data);
            } else {
                console.error(
                    "Ошибка при поиске оценки урока: " + (getEvaluation.data.error || "")
                );
                await this.$store.dispatch("clearEvaluation");
            }
        },
    },
};
</script>

<style lang="scss">
@import "./studyLesson.scss";
</style>
