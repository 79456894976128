<template>
    <div
        :class="
            tooltipType
                ? 'vipUpsellButtonWrapper vipUpsellButtonWrapper--' + tooltipType
                : 'vipUpsellButtonWrapper'
        "
    >
        <button class="vipUpsellButton" @click="() => onOpenModalAccess()">
            <Tooltip
                :tooltipIcon="require('@/assets/img/pages/webinars/vip.svg')"
                :text="
                    text ||
                        `Опция доступна только для студентов пакета &quot;VIP&quot;. Нажмите на иконку для подробной информации.`
                "
                :tooltipDirection="tooltipDirection"
                :tooltipType="tooltipType"
            />
        </button>

        <AccessRestricted
            v-if="isVisibleModalAccess"
            :accessRestrictedText="accessRestrictedText"
            :onClose="() => onCloseModalAccess()"
        />
    </div>
</template>

<script>
import Tooltip from "@/views/components/Tooltip/Tooltip";

import AccessRestricted from "@/views/partials/popups/accessRestricted/accessRestricted";

export default {
    name: "VipUpsellButton",

    components: {
        Tooltip,
        AccessRestricted,
    },

    data() {
        return {
            isVisibleModalAccess: false,
        };
    },

    props: {
        tooltipDirection: String,
        tooltipType: String,
        text: String,
        accessRestrictedText: String,
    },

    methods: {
        onOpenModalAccess() {
            this.isVisibleModalAccess = true;
        },
        onCloseModalAccess() {
            this.isVisibleModalAccess = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "vipUpsellButton.scss";
</style>
