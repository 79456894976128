<template>
    <div class="popup-layout ">
        <div class="popup popup--2 blockEvaluationModal">
            <button type="button" class="popup__close" @click="onClose"></button>
            <div>
                <div
                    v-if="!isVisibleAnswer"
                    :class="
                        evaluationValue !== '$' || evaluationValue !== '$$'
                            ? 'lessonEvaluation'
                            : 'lessonEvaluationInput'
                    "
                >
                    <p class="h2 blockEvaluationModal__subtitle">
                        Блок
                        {{
                            this.$store.getters.getUserProgress.progress.data[0].blocks[
                                Number(this.$route.params.block_priority) - 1
                            ].blockPriority
                        }}
                        пройден!
                    </p>
                    <p class="colorFiord">
                        {{
                            `${
                                evaluationValue === "$" || evaluationValue === "$$"
                                    ? ""
                                    : "Оцените этот блок"
                            }`
                        }}
                    </p>
                    <div
                        class="lessonEvaluationCard"
                        v-if="evaluationValue !== '$' && evaluationValue !== '$$'"
                    >
                        <p
                            :class="
                                evaluationValue === '$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--bad lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content lessonEvaluationCard__content--bad'
                            "
                            @click="() => handleClickGrade('$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Бесполезный
                            </span>
                        </p>
                        <p
                            :class="
                                evaluationValue === '$$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content'
                            "
                            @click="() => handleClickGrade('$$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Нормальный
                            </span>
                        </p>
                        <p
                            :class="
                                evaluationValue === '$$$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--good lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content lessonEvaluationCard__content--good'
                            "
                            @click="() => handleClickGrade('$$$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Полезный
                            </span>
                        </p>
                    </div>
                    <div
                        v-if="evaluationValue === '$' || evaluationValue === '$$'"
                        class="lessonEvaluationBlock"
                    >
                        <div class="form profileBox__form2">
                            <div class="formgroup">
                                <label class="text2 colorMirage">
                                    Напишите, пожалуйста, что именно нам стоит улучшить,
                                    для нас мега важно Ваше мнение🙏
                                </label>
                                <TextArea
                                    class="lessonEvaluation__textArea"
                                    rows="5"
                                    placeholder="Введите текст"
                                    :isError="isDescriptionLong"
                                    errorText="Вы не можете отправить более 1000 символов в этом текстовом поле"
                                    :optimalTextLength="200"
                                    optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                    :onChange="(event) => onChangeTextArea(event)"
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        :isDisabled="!evaluationValue"
                        class="profileBox__btn3"
                        title="Оценить"
                        :onClick="() => chooseProgram()"
                    />
                </div>

                <div v-if="isVisibleAnswer" class="lessonEvaluation">
                    <p class="profileBox__title2">
                        Блок пройден!
                    </p>
                    <img
                        class="lessonEvaluation__img"
                        alt="foldedHands"
                        src="@/assets/img/pages/study/lessonEvaluation/foldedHands.png"
                    />
                    <p class="profileBox__text2">
                        Спасибо за оценку!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Input from "@/views/components/Input/Input";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import StudyInfoProtector from "@/views/pages/study/studyInfoProtector/studyInfoProtector";
import TextArea from "@/views/components/TextArea/TextArea";

import iconDollar from "../../../../../assets/img/pages/study/lessonEvaluation/dollar.svg";

export default {
    name: "blockEvaluationModal",
    components: {
        Input,
        Button,
        TextArea,
        ButtonVideoProblem,
        StudyInfoProtector,
    },
    props: ["onClose"],

    watch: {
        "$route.params.lesson_id": function() {
            this.updateLessonEvaluation();
        },
    },

    async beforeMount() {
        this.updateLessonEvaluation();
    },

    data() {
        return {
            iconDollar,
            isVisibleAnswer: false,
            isVisibleNextTest: false,
            isDescriptionLong: false,
            evaluationValue: "",
            badOptionText: "",
        };
    },

    methods: {
        onSendAnswer() {
            this.isVisibleAnswer = true;
        },

        handleClickGrade(value) {
            this.evaluationValue = value;
        },

        onChangeTextArea(event) {
            this.badOptionText = event;
        },

        async updateLessonEvaluation() {
            const getEvaluation = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/evaluation"
            );

            if (getEvaluation && getEvaluation.data && getEvaluation.data.result) {
                await this.$store.dispatch("setEvaluation", getEvaluation.data);
            } else {
                console.error(
                    "Ошибка при поиске оценки урока: " + (getEvaluation.data.error || "")
                );
                await this.$store.dispatch("clearEvaluation");
            }
        },

        chooseProgram: async function() {
            const bodyByEvaluationValue =
                this.badOptionText !== ""
                    ? {
                          value: this.evaluationValue,
                          text: this.badOptionText,
                      }
                    : {
                          value: this.evaluationValue,
                      };

            if (this.badOptionText.length > 1000) {
                this.isDescriptionLong = true;
            } else {
                this.isDescriptionLong = false;
            }
            if (this.badOptionText.length < 1000) {
                const response = await this.axios.post(
                    `/programs/${this.$route.params.slug}/evaluation/block/${
                        this.$store.getters.getUserProgress.progress.data[0].blocks[
                            Number(this.$route.params.block_priority) - 1
                        ].blockId
                    }`,
                    bodyByEvaluationValue
                );
                if (response && response.data && response.data.result) {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Ваша оценка успешно отправлена",
                        },
                    });
                    this.updateLessonEvaluation();
                } else {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Не удалось отправить оценку, попробуйте позже",
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "./lessonEvaluation.scss";
</style>
