<template>
    <div :class="`popup popup--onboarding ${className ? className : ''}`">
        <div>
            <button type="button" class="popup__close" @click="onCloseModal"></button>
            <p class="text2 colorGray" v-if="isActiveSteps">
                Шаг {{ currentStep }} из {{ totalSteps }}
            </p>
            <div class="welcome">
                <p class="h3 onboarding__title">
                    {{ title }}
                </p>
                <p class="text2 colorFiord onboarding__description">
                    {{ description }}
                </p>
                <p class="onboardingButtons">
                    <Button
                        v-if="isNextButton"
                        :title="nextButtonTitle"
                        extraClass="button"
                        :onClick="() => onClose()"
                    />
                    <Button
                        v-if="isBackButton"
                        title="Назад"
                        extraClass="button"
                        :onClick="() => onBackButton()"
                    />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "OnboardingModal",
    components: { Button },
    props: [
        "className",
        "isActiveSteps",
        "currentStep",
        "totalSteps",
        "title",
        "description",
        "isNextButton",
        "nextButtonTitle",
        "isBackButton",
        "onClose",
        "onCloseModal",
        "onBackButton",
    ],
    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss">
@import "onboardModal.scss";
</style>
