<template>
    <Button
        extraClass="button--fullWidth button--videoProblem"
        :title="title"
        :isLink="true"
        :link="'/' + this.$route.params.slug + '/error-request'"
    />
</template>

<script>
import Button from "@/views/components/Button/Button";

export default {
    name: "ButtonVideoProblem",

    components: {
        Button,
    },

    props: {
        title: String,
    },
};
</script>

<style lang="scss" scoped>
@import "./ButtonVideoProblem.scss";
</style>
