<template>
    <div class="popup-layout">
        <div class="popup studyInfoProtector">
            <button type="button" class="popup__close" @click="onClose"></button>
            <p class="text studyInfoProtector__subtitle">
                Видеоинструкция по получению доступа к видео
            </p>

            <div class="studyInfoProtectorContent">
                <iframe
                    class="studyInfoProtectorContent__image"
                    src="https://player.vimeo.com/video/650362072"
                    title="Vimeo video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                ​
                <div class="studyInfoProtectorContent__instruction">
                    <p class="h2">
                        Инструкция по установке
                    </p>
                    <div class="studyInfoProtectorContentList">
                        <div class="studyInfoProtectorContentItem">
                            <p class="h4 colorFiord studyInfoProtectorContentItem__text">
                                1. Скачайте установочный файл инфопротектора
                            </p>
                            <Button
                                class="studyInfoProtectorContentItem__btn"
                                title="скачать"
                                :isLink="true"
                                :isExternalLink="true"
                                link="https://infoprotector.ru/online/download/"
                            />
                        </div>
                        <div class="studyInfoProtectorContentItem">
                            <p class="h4 colorFiord studyInfoProtectorContentItem__text">
                                2. Запустите скачанный установочный файл
                            </p>
                        </div>
                        <div class="studyInfoProtectorContentItem">
                            <p class="h4 colorFiord studyInfoProtectorContentItem__text">
                                3. Скопируйте Ваш ключ активации
                            </p>
                            <div class="studyInfoProtectorCopy">
                                <div class="studyInfoProtectorCopy__btn">
                                    <div class="studyInfoProtectorCopy__btn_text">
                                        <p class="title1 colorGray">
                                            Ключ активации
                                        </p>
                                        <p class="h4 colorFiord">
                                            {{ lesson.key || "" }}
                                        </p>
                                    </div>
                                    <Button
                                        title="Скопировать"
                                        v-clipboard:copy="`${lesson.key || ''}`"
                                        v-clipboard:error="onCopyError"
                                        :onClick="() => onVisibleText()"
                                    />
                                </div>
                                <p
                                    :class="
                                        `title1 colorDenim studyInfoProtectorCopy__successText ${
                                            isVisibleText
                                                ? 'transitionIn'
                                                : 'transitionOut'
                                        }`
                                    "
                                >
                                    Ключ активации скопирован
                                </p>
                            </div>
                        </div>
                        <div class="studyInfoProtectorContentItem">
                            <p class="h4 colorFiord studyInfoProtectorContentItem__text">
                                4. Откройте видео и вставьте ключ активации в появившемся
                                окне
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "studyInfoProtector",
    components: { Button },
    props: ["onClose"],
    data() {
        return {
            isVisibleText: false,
        };
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },
    },

    methods: {
        onCloseModal() {
            this.onClose();
        },
        async onCopyError(e) {
            await this.$store.dispatch("setAlert", {
                alert: {
                    isActive: true,
                    type: "error",
                    text: "Проблема при копировании ключа.",
                },
            });
        },

        onVisibleText() {
            this.isVisibleText = true;

            setTimeout(() => (this.isVisibleText = false), 3000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "studyInfoProtector.scss";
</style>
